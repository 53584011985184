// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-index-js": () => import("/opt/render/project/src/src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("/opt/render/project/src/src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/render/project/src/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/render/project/src/.cache/data.json")

